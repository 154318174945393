@import "vendor/showcar_ui/01-settings/variables";

.pe-pricelabel-v2 {
  width: 106px;
  &:focus {
    outline: none;
  }
}

.pe-pricelabel-v2--text {
  margin-bottom: $XS;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  font-size: 13px;
}

.pe-pricelabel-v2--bar {
  width: auto;
  height: 6px;
  position: relative;
  &::before {
    content: '';
    background-color: $black-30;
    width: 100%;
    height: 4px;
    position: absolute;
    top: 1px;
    border-radius: 2px;
  }
  &::after {
    content: '';
    background-color: #DCDCDC;
    width: 100%;
    height: 6px;
    position: absolute;
    border-radius: 2px;
  }
}

.pe-pricelabel-v2--top-price {
  .pe-pricelabel-v2--bar {
    &::after {
      background-color: #3A7410;
      width: 100%;
    }
  }
}

.pe-pricelabel-v2--good-price {
  .pe-pricelabel-v2--bar {
    &::after {
      background-color: #7DC32F;
      width: 80%;
    }
  }
}

.pe-pricelabel-v2--fair-price {
  .pe-pricelabel-v2--bar {
    &::after {
      background-color: #ADD042;
      width: 60%;
    }
  }
}

.pe-pricelabel-v2--somewhatexpensive-price {
  .pe-pricelabel-v2--bar {
    &::after {
      background-color: #F2CA26;
      width: 40%;
    }
  }
}

.pe-pricelabel-v2--expensive-price {
  .pe-pricelabel-v2--bar {
    &::after {
      background-color: #E67E23;
      width: 20%;
    }
  }
}

.pe-pricelabel-v2--not-available {
  .pe-pricelabel-v2--bar {
    &::after {
      display: none;
    }
  }
}

.pe-pricelabel-v2--toohigh-price {
  .pe-pricelabel-v2--bar {
    &::after {
      display: none;
    }
  }
}

.pe-pricelabel-v2--toolow-price {
  .pe-pricelabel-v2--bar {
    &::after {
      display: none;
    }
  }
}
